import { render, staticRenderFns } from "./typeActivity.vue?vue&type=template&id=08125ca1&scoped=true&"
import script from "./typeActivity.vue?vue&type=script&lang=js&"
export * from "./typeActivity.vue?vue&type=script&lang=js&"
import style0 from "./typeActivity.vue?vue&type=style&index=0&id=08125ca1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08125ca1",
  null
  
)

export default component.exports