<!-- 页面程序 -->
<template>
    <div :style="{ 'background': backGroundColor }">
        <img class="topImage" :src="activityTopImg" />

        <div class="porject_items">
            <section class="porject_item" v-for="(item, index) in activityList" :key="index">
                <div class=" porject_item_top">
                    <img class="porject_bgImg"
                        src="http://resources.sxdjcq.com/mini/wash/icon_discount_title_bg.png" v-if="topHasImg"/>
                    <span class="porject_item_title" :style="{ 'color': topHasImg ? '#EB636E' : '#1B9BD8','font-size' : topHasImg ? '14px' : '18px','font-weight':topHasImg ? bold : '500'}">{{ item.firstTitle }}</span>
                </div>
                <div class="porject_bottom">
                    <div class="porject" @click="getServiceData(item.aslrList[0])">
                        <div style="width: 56%;height: 100%; display: flex;flex-direction: column;">
                            <span class="porject_name">{{item.aslrList[0].projectName}}</span>
                            <span class="porject_desc">{{item.aslrList[0].description}}</span>
                            <div style="margin-top: 20px; height: 50px;align-items: center;display: flex;justify-content: space-between;">
                                <div style="display: flex;justify-content: center;align-items: center;width: 100px;height: 50px; margin-left: 5px;">
                                    <img src="http://resources.sxdjcq.com/mini/wash/icon_activity_btn_bg.png" style="height: 30px; width: 100px; position: absolute;" />
                                    <span style="position: absolute; color: white; font-size: 16px;">￥{{ 1 > item.aslrList[0].oltCount ?(item.aslrList[0].minimumNewcomerDiscount / 100).toFixed(2) :(item.aslrList[0].lowestDiscountPrice / 100).toFixed(2)}}</span>
                                </div>
                                <span style="color: #666666;text-decoration: line-through;font-size: 12px; margin-right: 5px;">￥{{(item.aslrList[0].theLowestPrice / 100).toFixed(2)}}</span>
                            </div>
                        </div>
                        <div style="width: 44%;height: 100%;">
                            <img class="w100 h100" :src="item.aslrList[0].photoPath" />
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="porject_recommend">
            <section class="project_title_view" :style="{ 'color': titleColor }">
                <span class="project_title_view_line" :style="{ 'background': titleColor }"></span>
                <span style="margin-left: 20px;margin-right: 20px;">推荐服务</span>
                <span class="project_title_view_line" :style="{ 'background': titleColor }"></span>
            </section>
            <section class="porject_recommend_bottom">
                <div class="porject_recommend_scroll_item" v-for="(item, index) in recommendList" :key="index"
                    @click="getServiceData(item)">
                    <section style="display: flex;flex-direction: column;">
                        <img class="spikeByTime_img" style="border-radius:10px; height: 120px;" :src="item.photoPath" />
                        <span class="spikeByTime_name">{{ item.projectName }}</span>
                        <span class="spikeByTime_desc">{{ item.description }}</span>
                        <div class="spikeByTime_price">
                            <span class="font16" style="width: 50%;font-weight: 800; color: #EE4908;">￥{{ 1 > item.oltCount ?
                                    (item.minimumNewcomerDiscount / 100).toFixed(2) :
                                    (item.lowestDiscountPrice / 100).toFixed(2)
                            }}</span>
                            <span style="color: #666666;text-decoration: line-through;font-size: 12px;">￥{{
                                    (item.theLowestPrice / 100).toFixed(2)
                            }}</span>
                        </div>
                    </section>
                </div>
            </section>
        </div>

        <div class="bottom_img">
            <img :src="activityBottomImg" style="width: 100% ; height: 100%; " />
        </div>

    </div>
</template>


<script>
import wx from "weixin-js-sdk-ts";

export default {
    components: {},
    data() {
        return {
            activityId: this.$route.query.activityId,
            memberId: this.$route.query.memberId,
            activityTopImg: "",
            activityBottomImg: "",
            activityList: [],
            recommendList: [],
            topHasImg: this.$route.query.activityId == 2 ? false : true,
            backGroundColor: this.$route.query.activityId == 2 ? '#9FE0FF' : '#FFEEF3',
            titleColor: this.$route.query.activityId == 2 ? '#0E8AC5' : '#EB636E',
        };
    },
    methods: {
        getBaseInfo() {
            let params = {
                activityId: this.activityId,
                memberId: this.memberId,
                activityList: [],
            };
            this.$axios({
                url: this.$requests.member.getNewActiveDataList,
                method: "post",
                data: params,
            }).then((res) => {
                let wrapper = res.data || {};
                let list = wrapper.data || [];
                let top_data = list.shift()
                let array = list.reverse()
                let bottom_data = array.shift()
                let activityList = list.reverse()

                this.activityTopImg = top_data.aslrList[0].uri,
                    this.activityBottomImg = bottom_data.aslrList[0].uri,
                    this.activityList = activityList
            }).catch((e) => { });
        },
        getReferralServiceList() {
            let params = {
                placeAnOrder: 0,
                memberId: this.memberId,
            };
            this.$axios({
                url: this.$requests.member.getNewActiveSalesList,
                method: "post",
                data: params,
            }).then((res) => {
                let wrapper = res.data || {};
                this.recommendList = wrapper.data || [];
            }).catch((e) => { });
        },
        getServiceData(e) {
            var ua = navigator.userAgent.toLowerCase()
            if (ua.match(/MicroMessenger/i) === 'micromessenger' || ua.indexOf('micromessenger') !== -1) {
            //第一种情况在微信小程序中没有奏效，第二种奏效了
            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                wx.miniProgram.getEnv((res) => {
                    if (res.miniprogram) {
                        console.log('在小程序')
                        let path = "/pages/subPackages/orderSubPackages/servicedetails/servicedetails?projectId=" + e.projectId     
                        wx?.miniProgram.navigateTo({
                            url: path
                        })
                    } else {
                        console.log('不在小程序')
                        let href = "next://getServiceData" + ',' + e.projectId
                        window.location.href = href;
                    }
                })
            } else {
                let href = "next://getServiceData" + ',' + e.projectId
                window.location.href = href;
                return
            }
        },
    },
    mounted() {
        // 获取活动详情信息
        this.getBaseInfo();
        // 获取推荐数据
        this.getReferralServiceList();
    },
};
</script>

<style lang="less" scoped>
.topImage {
    width: 100%;
}

.porject_items {
    margin-top: 10px;
    background-color: white;
    margin-left: 2%;
    width: 96%;
    border-radius: 10px;
    padding-bottom: 15px;
}

.porject_item {
    width: 100%;
    height: 190px;
    padding-bottom: 1px;
}

.porject_item_top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.porject_bgImg {
    height: 30px;
    position: relative;
}
.porject_item_title {
    position: absolute;
    margin: auto;
    text-align: center;
}

.project_title_view {
    display: flex;
    justify-content:center;
    align-items:center;
    height: 40px;
}

.project_title_view_line {
    width: 50px;
    height: 1px;
}

.porject_bottom{
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.porject{
  width: 92%;
  height: 100%;
  border: 2px solid #5CB4DE;
  box-shadow: 0px 1px 8px 0px rgba(41,144,143,0.2300);
  border-radius: 20px;
  display: flex;
  overflow: hidden;
}

.porject_name{
    margin-top: 15px;
    font-size: 17px;
    color: #333333;
    font-weight: 500;
    margin-left: 5%;
    margin-right: 5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.porject_desc{
    margin-top: 10px;
    font-size: 14px;
    color: #666666;
    font-weight: 500;
    margin-left: 5%;
    margin-right: 5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.porject_recommend_bottom {
    height: 205px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}

.porject_recommend_scroll_item {
    margin-top: 5px;
    margin-right: 5px;
    height: 180px;
    width: 35vw;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 9px 1px rgba(179, 110, 69, 0.4600);
    border-radius: 5px;
    overflow: hidden;
    display: inline-table;
}

.component_content_price {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.component_content_price_couponFee {
    width: 100%;
    height: 60%;
    font-weight: 800;
    color: #EE4908;
    display: flex;
    justify-content: center;
    align-items: center;
}

.component_content_price_name {
    width: 100%;
    height: 40%;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spikeByTime_img {
    width: 100%;
    height: 55%;
}

.spikeByTime_name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw;
    margin-top: 2px;
    margin-left: 1vw;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
}

.spikeByTime_desc {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw;
    margin-top: 5px;
    margin-left: 1vw;
    font-size: 11px;
    color: #666666;
    font-weight: 500;
}

.spikeByTime_price {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20px;
    align-items: baseline;
}
</style>